import React, { useRef } from 'react'
import useClickOutsideCloseComponent from 'hooks/useClickOutsideCloseComponent'

import stackImage1 from 'images/photonicQc/zoomstack/stack_1.svg'
import stackImage2 from 'images/photonicQc/zoomstack/stack_2.svg'
import stackImage3 from 'images/photonicQc/zoomstack/stack_3.svg'
import stackImage4 from 'images/photonicQc/zoomstack/stack_4.svg'
import stackImage5 from 'images/photonicQc/zoomstack/stack_5.svg'

type StackData = {
  image: unknown
  extraClass?: string
  extraStyle?: {
    [key: string]: string
  }
  title: string | null
  details: string | null
}

const offsetPercent = 13

const stackData: StackData[] = [
  {
    image: stackImage1,
    extraClass: 'z-40',
    title: null,
    details: null,
  },
  {
    image: stackImage2,
    extraClass: 'z-30',
    title: 'State Preparation',
    details:
      'A single qubit is generated, with some probability, by sending laser light into an array of optical elements, and then counting the photons at the output using transition edge sensors. A special photon number pattern indicates the desired qubit has been created.',
  },
  {
    image: stackImage3,
    extraClass: 'z-20',
    title: 'Multiplexing',
    details:
      'To boost the probability of generating a qubit, outputs of state preparation are sent to a multiplexer: a large network of optical switches. If there is at least one success, the qubit is routed to the next stage. Otherwise, another useful quantum state is substituted in.',
  },
  {
    image: stackImage4,
    extraClass: 'z-10',
    title: 'Stitcher',
    details:
      'In the stitcher, beamsplitters entangle the qubits in space and time to produce a three-dimensional quantum state called a cluster state. This type of state is required to achieve fault-tolerance.',
  },
  {
    image: stackImage5,
    extraClass: 'z-0',
    title: 'QPU (Quantum Processing Unit)',
    details:
      'The QPU  implements the quantum logic and corrects for errors that build up during the computation. Measuring each qubit with room-temperature photodetectors has the same effect as applying a quantum gate. A quantum algorithm is just a recipe of measurements to perform in the QPU.',
  },
]

type ArchitectureStackProps = {
  whichTabExpanded: number | null
  setWhichTabExpanded: React.Dispatch<React.SetStateAction<number | null>>
}

const ArchitectureStack = ({
  whichTabExpanded,
  setWhichTabExpanded,
}: ArchitectureStackProps) => {
  const [stackItemContainerHeight, setStackItemContainerHeight] =
    React.useState<number>(346)
  const architectureStackItemRef = useRef<HTMLDivElement>(null)

  // update stack height property in state
  const handleResize = () => {
    if (null !== architectureStackItemRef.current) {
      setStackItemContainerHeight(architectureStackItemRef.current.clientHeight)
    }
  }
  React.useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    // event listener cleanup
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // esc to exit stack details
  React.useEffect(() => {
    const handleEscOutside = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        whichTabExpanded !== null && setWhichTabExpanded(null)
      }
    }
    document.addEventListener('keydown', handleEscOutside)
    return () => {
      document.removeEventListener('keydown', handleEscOutside)
    }
  })

  // clicking away closes details
  const stackTextRefs = [useRef<HTMLLIElement>(null)]
  const onClickOutside = (index: number) => {
    // check if the click is triggered from currently open "details", and if so close
    if (index === whichTabExpanded) {
      setWhichTabExpanded(null)
    }
  }
  stackData.forEach((_item, index) => {
    stackTextRefs.push(useRef<HTMLLIElement>(null))
    useClickOutsideCloseComponent(stackTextRefs[index], () =>
      onClickOutside(index)
    )
  })

  return (
    <>
      <div className="w-full">
        <div className="flex flex-col justify-center w-full">
          {/* STACKS */}
          <div
            style={{
              paddingBottom: `${
                (stackItemContainerHeight * 1.25 - stackItemContainerHeight) / 2
              }px`,
            }}
          >
            <div
              className="relative w-full text-center"
              // height: container height = item height / 1 - final offset as decimal
              style={{
                height: `calc(${
                  stackItemContainerHeight /
                  (1 - (offsetPercent / 100) * (stackData.length - 1))
                }px)`,
              }}
            >
              {stackData.map((item, index) => {
                const itemExpanded = index === whichTabExpanded ? true : false
                // only set ref for index 0
                const ref = index === 0 ? architectureStackItemRef : null
                return (
                  <div
                    className={`absolute architecture-stack-layer transition-all duration-500 ease-in-out w-1/2 ${
                      item.extraClass ? item.extraClass : ''
                    } ${itemExpanded ? 'z-50' : ''} ${
                      // sets opacity lower if another item selected
                      whichTabExpanded !== null && !itemExpanded
                        ? 'opacity-50'
                        : 'opacity-100'
                    }
                  `}
                    key={index}
                    style={{
                      top: `${offsetPercent * index}%`,
                    }}
                    ref={ref}
                  >
                    <img
                      className={`w-full
                      transform transition-all duration-500 ease-in-out 
                      ${itemExpanded ? 'scale-125' : ''}
                    `}
                      onLoad={() => index === 0 && handleResize()}
                      alt={item.title || ''}
                      src={String(item.image)}
                    />
                  </div>
                )
              })}
              {/* TAB BUTTONS */}
              {stackData.map((item, index) => {
                const itemExpanded = index === whichTabExpanded ? true : false
                const otherItemExpanded =
                  whichTabExpanded !== null && !itemExpanded
                const alignLeft = index % 2 !== 0
                return (
                  <div
                    className="absolute w-full"
                    style={{
                      top: `${offsetPercent * index}%`,
                    }}
                    key={index}
                  >
                    <div
                      className={`relative z-60`}
                      style={{
                        top: `calc(${stackItemContainerHeight * 0.5}px - 1rem)`,
                      }}
                    >
                      <div
                        className={`transition-all duration-1000 z-60 ${
                          alignLeft ? '' : 'justify-end'
                        } ${otherItemExpanded ? 'hidden' : 'flex'}`}
                      >
                        {/* SHOW BUTTON */}
                        {item.details && (
                          <button
                            onClick={() => {
                              setWhichTabExpanded(
                                index === whichTabExpanded ? null : index
                              )
                            }}
                            aria-expanded={itemExpanded}
                            aria-label={`show details about ${item.title}`}
                            className={`p-1 btn btn-1 rounded-full transform rotate-45 transition-all duration-1000 inline-block opacity-100 ${
                              itemExpanded ? 'hidden opacity-0' : ''
                            } ${
                              alignLeft
                                ? 'ml-1/6 md:ml-1/5'
                                : 'mr-1/6 md:mr-1/5'
                            }`}
                          >
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.3 0.709971C12.91 0.319971 12.28 0.319971 11.89 0.709971L6.99997 5.58997L2.10997 0.699971C1.71997 0.309971 1.08997 0.309971 0.699971 0.699971C0.309971 1.08997 0.309971 1.71997 0.699971 2.10997L5.58997 6.99997L0.699971 11.89C0.309971 12.28 0.309971 12.91 0.699971 13.3C1.08997 13.69 1.71997 13.69 2.10997 13.3L6.99997 8.40997L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.40997 6.99997L13.3 2.10997C13.68 1.72997 13.68 1.08997 13.3 0.709971Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        )}
                        {/* DESKTOP DETAIL BOXES */}
                        {itemExpanded && (
                          <div
                            className={`hidden relative transition-all duration-1000 bg-white rounded-md border border-primary-200 p-5 text-left opacity-100 lg:inline-block`}
                            ref={stackTextRefs[index]}
                            style={{ width: 'calc(20% + 24px)' }}
                          >
                            <button
                              className={`absolute top-3 right-3`}
                              aria-label="close description"
                              onClick={() => {
                                setWhichTabExpanded(null)
                              }}
                              autoFocus
                            >
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.3 0.709971C12.91 0.319971 12.28 0.319971 11.89 0.709971L6.99997 5.58997L2.10997 0.699971C1.71997 0.309971 1.08997 0.309971 0.699971 0.699971C0.309971 1.08997 0.309971 1.71997 0.699971 2.10997L5.58997 6.99997L0.699971 11.89C0.309971 12.28 0.309971 12.91 0.699971 13.3C1.08997 13.69 1.71997 13.69 2.10997 13.3L6.99997 8.40997L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.40997 6.99997L13.3 2.10997C13.68 1.72997 13.68 1.08997 13.3 0.709971Z"
                                  fill="#23262C"
                                />
                              </svg>
                            </button>
                            <p className="h7 text-grey-800" role="heading">
                              {item.title}
                            </p>
                            <p className="mt-2">{item.details}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {/* MOBILE DETAIL BOXES */}
        <div>
          {stackData.map((item, index) => {
            const itemExpanded = index === whichTabExpanded ? true : false

            if (itemExpanded) {
              return (
                <div
                  className={`transition-all ease-linear duration-500 mt-5 opacity-100 visible max-h-96 lg:hidden`}
                  key={index}
                >
                  <div
                    className={`relative bg-white rounded-md border border-primary-200 p-5 text-left inline-block`}
                    key={index}
                  >
                    <button
                      className={`absolute top-3 right-3`}
                      aria-label="close description"
                      onClick={() => {
                        setWhichTabExpanded(null)
                      }}
                      autoFocus
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.3 0.709971C12.91 0.319971 12.28 0.319971 11.89 0.709971L6.99997 5.58997L2.10997 0.699971C1.71997 0.309971 1.08997 0.309971 0.699971 0.699971C0.309971 1.08997 0.309971 1.71997 0.699971 2.10997L5.58997 6.99997L0.699971 11.89C0.309971 12.28 0.309971 12.91 0.699971 13.3C1.08997 13.69 1.71997 13.69 2.10997 13.3L6.99997 8.40997L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.40997 6.99997L13.3 2.10997C13.68 1.72997 13.68 1.08997 13.3 0.709971Z"
                          fill="#23262C"
                        />
                      </svg>
                    </button>
                    <p className="h7 text-grey-800" role="heading">
                      {item.title}
                    </p>
                    <p className="mt-2">{item.details}</p>
                  </div>
                </div>
              )
            } else return null
          })}
        </div>
      </div>
    </>
  )
}

export default ArchitectureStack

import * as React from 'react'
import ArchitectureStack from 'components/photonicQc/ArchitectureStack'

const PhotonicsArchitecture = () => {
  const [whichTabExpanded, setWhichTabExpanded] = React.useState<number | null>(
    null
  )

  return (
    <div className="flex flex-row flex-wrap justify-center">
      <ArchitectureStack
        whichTabExpanded={whichTabExpanded}
        setWhichTabExpanded={setWhichTabExpanded}
      />
    </div>
  )
}

export default PhotonicsArchitecture

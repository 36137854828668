/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import Layout from 'components/Layout'
import VideoHeroLocal from 'components/VideoHeroLocal/VideoHeroLocal'
import Architecture from 'components/photonicQc/Architecture'
import Modal from 'components/Modal'
import ResearchBoxes from 'components/research/ResearchBoxes'

import ImageVideoPlayIconSvg from 'images/videoPlayIconSvg'

import heroVideo from 'videos/x-series/one-million-qubits-trim_compressed.mp4'
import heroVideoThumbnail from 'videos/thumbnails/million-qubits-thumbnail.jpeg'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getImageDataByFilename } from '../utils/getImageDataByFilename'
import Seo from '../components/Seo'

const photonicsAdvantages: {
  image: string
  title: string
  greyBg?: boolean
}[] = [
  {
    image: 'room_temperature_crop.jpg',
    title: 'Room temperature computation',
  },
  {
    image: 'manufacturable_crop.jpg',
    title: 'Manufacturability',
  },
  {
    image: 'RHG_matching.png',
    title: 'Error correction flexibility',
    greyBg: true,
  },
  {
    image: 'robust_crop.jpg',
    title: 'Modular and networkable',
  },
  {
    image: 'fast_clock_speeds.jpg',
    title: 'Fast clock speeds',
  },
  {
    image: 'existing_comp_infra.jpg',
    title: 'Telecom compatibility',
  },
]

const PhotonicQc = () => {
  const data = useStaticQuery(query)

  const research = data.allResearchJson.nodes
  return (
    <Layout>
      <div>
        {/* HERO */}
        <VideoHeroLocal
          copyElement={
            <>
              <p className="text-white subtitle opacity-70">// FTQC</p>
              <h1>Millions of qubits powered by light</h1>
              <p className="main-p">
                Learn more about Xanadu’s FTQC blueprint.
              </p>
              <div className="mt-5.5">
                <Modal
                  interaction={
                    <div className="inline watch-video">
                      <ImageVideoPlayIconSvg
                        circleColor="text-white"
                        widthAndHeight={30}
                      />
                      <span className="swipe-link swipe-link-white ml-2.5 text-lg font-semibold border-b-2 border-white">
                        <span data-hover="Watch Video">Watch Video</span>
                      </span>
                    </div>
                  }
                  title={
                    'How to Build a Quantum Computer with One Million Qubits'
                  }
                  content={
                    <div className="video-wrapper">
                      <iframe
                        src="https://www.youtube.com/embed/SD6TH7GZ1rM?autoplay=1"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  }
                />
              </div>
            </>
          }
          video={heroVideo as string}
          thumbnail={heroVideoThumbnail as string}
        />

        {/* ADVANTAGES */}
        <div className="standard-page-section bg-grey-300">
          <div className="inner-container jumptarget" id="advantages">
            <div>
              <p className="subtitle">// Why Photonics</p>
              <h2>The unique advantages of Photonics</h2>
            </div>
            <div className="mt-7.5">
              <div className="flex flex-row flex-wrap gap-y-20 sm:gap-y-12 md:gap-y-4">
                {photonicsAdvantages.map((item, i) => {
                  return (
                    <div className="w-full p-3 sm:w-1/2 lg:w-1/3" key={i}>
                      <div
                        className={`${
                          item.greyBg ? 'photonics-grey-bg' : ''
                        } w-full h-full max-h-[240px]`}
                      >
                        <GatsbyImage
                          className="object-cover w-full h-full"
                          image={getImageDataByFilename(item.image)}
                          alt=""
                        />
                      </div>
                      <h6 className="mt-2.5 text-center lg:mt-4">
                        {item.title}
                      </h6>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        {/* ARCHITECTURE */}
        <div className="standard-page-section inner-container">
          {/* top text */}
          <div className="flex justify-center jumptarget" id="architecture">
            <div className="text-center max-w-160">
              <p className="mt-10 subtitle md:mt-0">// Architecture</p>
              <h2>Measurement-based quantum computing</h2>
              <p className="main-p">
                Xanadu’s architecture is modular and capable of scaling to one
                million qubits through optical networking. To get there, we are
                developing a manufacturable, fault-tolerant module consisting of
                four components that work together to generate, entangle, and
                process thousands of qubits.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="extended-container">
            <Architecture />
          </div>
          <div className="text-center inner-container">
            <Link
              to="/blog/from-a-state-of-light-to-state-of-the-art-the-photonic-path-to-millions-of-qubits"
              className="mt-5 mb-5 mx-2.5 btn btn-lg btn-1"
            >
              Read the blog post
            </Link>
            <a
              href="https://arxiv.org/abs/2010.02905"
              target="_blank"
              className="mt-5 mx-2.5 btn btn-lg btn-2"
              rel="noopener noreferrer"
            >
              Read the blueprint paper
            </a>
          </div>
        </div>
        {/* RESEARCH */}
        <div className="standard-page-section inner-container">
          <p className="subtitle">// Research</p>
          <h2 className="mt-2.5">Featured publications</h2>
          <div className="mt-15">
            <ResearchBoxes articles={research.slice(0, 6)} />
          </div>
          <div className="mt-20 text-center">
            <Link to="/research" className="btn btn-lg btn-1">
              All research
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const query = graphql`
  query {
    allResearchJson(
      sort: { order: DESC, fields: Date }
      filter: { featured: { eq: true } }
    ) {
      nodes {
        id
        Title
        Date(formatString: "MMMM D, YYYY")
        ID
        URL
      }
    }
  }
`

export default PhotonicQc

export const Head = () => {
  return (
    <Seo
      title="Photonics"
      description="Xanadu is building practical and scalable quantum computers using photonic technology"
      image="architecture.png"
    />
  )
}
